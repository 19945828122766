import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  findMenuId(menuId: string, menus: any[]) {
    for (var menu of menus) {
      if (menu.menuId == menuId) return true;
      if (menu.subMenus?.length) {
        if (this.findMenuId(menuId, menu.subMenus)) return true;
      }
    }
    return false;
  }

  checkHasPermission(requiredPermission: string) {
    const currentUser = this.authService.currentUserValue;
    return this.findMenuId(requiredPermission, currentUser.subMenus || []);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authService.currentUserValue;
    const requiredPermission = route.data['permission'] || "*";

    if (currentUser) {
      if (requiredPermission == "*") {
        return true;
      } else {
        if (!this.checkHasPermission(requiredPermission)) {
          this.router.navigate(['/']);
          return false;
        }
        return true;
      }
    }

    // not logged in so redirect to login page with the return url
    this.authService.logout();
    return false;
  }
}
